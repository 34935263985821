import request from '@/utils/request'

// 门店管理
// 门店列表
export const storeListAPI = (params) => {
  return request({
    url: '/storeList',
    method: 'GET',
    params
  })
}
